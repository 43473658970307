import styled from '@emotion/styled'
import { sizes } from 'ui/theme'

export const ContentArea = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin: auto;
	max-width: ${sizes.large};
	min-height: 100%;
	padding: 1rem;
`

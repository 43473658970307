export const generateAuthorizationHeader = ({
	accessToken,
	tokenType,
}) => ({
	Authorization: `${tokenType} ${accessToken}`,
})

export const getResponseJsonOrFail = response => {
	if (!response.ok)
		throw Error(
			`Response failed with ${response.status} status`,
		)

	return response.json()
}

import { HomePage } from 'pages/creator'
import { PlaylistPage } from 'pages/participant'
import { LandingPage, LoginPage } from 'pages/public'
import { OauthCallback, LogoutPage } from 'pages/auth'
import { generateRoute } from 'config/routes'

export const routes = [
	// auth
	{
		path: generateRoute('oauthCallback'),
		exact: true,
		component: OauthCallback,
	},
	{
		path: generateRoute('logout'),
		exact: true,
		component: LogoutPage,
	},

	// public pages
	{
		path: generateRoute('landing'),
		exact: true,
		component: LandingPage,
	},
	{
		path: generateRoute('login'),
		exact: true,
		component: LoginPage,
	},

	// participant pages
	{
		path: generateRoute('playlist'),
		exact: true,
		component: PlaylistPage,
	},

	// creator pages
	{
		path: generateRoute('home'),
		exact: true,
		component: HomePage,
	},
]

export const getUser = ({ database, storage }) => () =>
	database.getUserById(storage.getUserId())

export const refreshUserFavorites = ({
	database,
	storage,
	provider,
}) => () =>
	provider.findFavorites().then(trackList => {
		database.updateUser(storage.getUserId(), {
			favorites: trackList.map(track => ({
				spotifyId: track.id,
			})),
		})

		// @TODO store tracks based on events
		database.createTrackList(trackList)
	})

import { errorHandler } from 'services/errorHandler'
import {
	extractDataFromDocumentWithId,
	extractDocumentId,
	ensureDocumentExists,
	firstFromQuerySnapshotOrFail,
} from './utils'

/*
{
	id: String
	email: String
	createdAt: DateTime
	updatedAt: DateTime | Null
	spotify: {
		userId: String
		name: String
		email: String
		avatar: Url
		hasPaidAccount: Boolean
	}
	favorites: Array<Track>
}
*/

const COLLECTION_USER = 'users'

export const getUserById = firestore => id =>
	firestore()
		.collection(COLLECTION_USER)
		.doc(id)
		.get()
		.then(
			ensureDocumentExists(
				`User with id "${id}" not found`,
			),
		)
		.then(extractDataFromDocumentWithId)
		.catch(errorHandler)

export const getUserByEmail = firestore => email =>
	firestore()
		.collection(COLLECTION_USER)
		.where('email', '==', email)
		.get()
		.then(
			firstFromQuerySnapshotOrFail(
				`User with email "${email}" not found`,
			),
		)
		.then(extractDataFromDocumentWithId)
		.catch(errorHandler)

export const createUser = firestore => data =>
	firestore()
		.collection(COLLECTION_USER)
		.add({
			...data,
			createdAt: Date.now(),
			updatedAt: null,
		})
		.then(doc => ({
			...data,
			id: extractDocumentId(doc),
		}))
		.catch(errorHandler)

export const updateUser = firestore => (id, data) =>
	getUserById(firestore)(id)
		.then(oldData => {
			const updatedUser = {
				...oldData,
				...data,
				updatedAt: Date.now(),
			}

			firestore()
				.collection(COLLECTION_USER)
				.doc(id)
				.set(updatedUser)
				.catch(errorHandler)

			return updatedUser
		})
		.catch(errorHandler)

export const upsertUserByEmail = firestore => data =>
	getUserByEmail(firestore)(data.email)
		.then(({ id }) => updateUser(firestore)(id, data))
		.catch(() => createUser(firestore)(data))
		.catch(errorHandler)

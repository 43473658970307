import qs from 'qs'
import { env } from 'config'
import { routes } from './routes'

/*
{
	tokenType: String
	accessToken: String,
	refreshToken: String
	scopes: Array<String>
	expiresAt: DateTime
}
*/

export const isTokenValid = token =>
	token &&
	token.accessToken &&
	token.refreshToken &&
	token.tokenType &&
	token.expiresAt &&
	Array.isArray(token.scopes) &&
	token.scopes.length > 0

export const isTokenExpired = token =>
	token.expiresAt < Date.now()

const parseAuthTokenResponse = result => ({
	accessToken: result.access_token,
	refreshToken: result.refresh_token,
	tokenType: result.token_type,
	expiresAt: Date.now() + result.expires_in * 1000,
	scopes: result.scope.split(' '),
})

const fetchFromAuthApi = body =>
	fetch(routes.account.token(), {
		method: 'POST',
		body: qs.stringify(body),
		headers: {
			Authorization:
				'Basic ' +
				btoa(
					`${env.spotify.clientId}:${env.spotify.clientSecret}`,
				),
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
		.then(response => {
			if (!response.ok)
				throw Error('Failed to authenticate on spotify.')

			return response.json()
		})
		.then(parseAuthTokenResponse)

export const fetchAuthTokens = ({ callbackUrl, code }) =>
	fetchFromAuthApi({
		grant_type: 'authorization_code',
		code,
		redirect_uri: callbackUrl,
	})

export const fetchRefreshedAuthTokens = refreshToken =>
	fetchFromAuthApi({
		grant_type: 'refresh_token',
		refresh_token: refreshToken,
	}).then(result => ({
		...result,
		refreshToken,
	}))

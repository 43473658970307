import React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { TextSmall } from 'ui/components/index'
import { borders, buttons, sizes } from 'ui/theme'
import { ParticipantsList } from './ParticipantsList'
import { EditableTitle } from 'ui/components/EditableTitle'

const MAX_PARTICIPANTS = 5

const lengthToHourAndMinutes = seconds => {
	const SECONDS_IN_HOUR = 60 * 60
	const hours = Math.floor(seconds / SECONDS_IN_HOUR)
	const remainingSeconds = seconds % SECONDS_IN_HOUR
	const minutes = Math.round(remainingSeconds / 60)

	return `${hours}h${minutes}`
}

const timestampToEuropeanFormat = timestamp => {
	const date = new Date(timestamp)

	return `${date.getDate()}/${date.getMonth() + 1}/${date
		.getFullYear()
		.toString()
		.replace(/^\d{2}/, '')}`
}

export const PlaylistCard = ({
	createdAt,
	participants,
	ownerName,
	songName,
	songImage,
	title,
	totalSeconds,
	totalSongs,
	...rest
}) => (
	<WrapperStyled {...rest}>
		<ThumbnailStyled>
			<img
				src={songImage}
				alt={`Thumbnail for ${songName}`}
			/>
			<FontAwesomeIcon icon={faPlay} />
		</ThumbnailStyled>
		<div>
			<EditableTitle title={title} />
			<Metadata>
				<TextSmall>Created by {ownerName}</TextSmall>
				<TextSmall>
					<FontAwesomeIcon icon={faCalendarAlt} />
					{timestampToEuropeanFormat(createdAt)}
				</TextSmall>
			</Metadata>
			<TextSmall>
				{totalSongs} tracks,{' '}
				{lengthToHourAndMinutes(totalSeconds)}
			</TextSmall>
			<ParticipantsList
				maxParticipants={MAX_PARTICIPANTS}
				participants={participants}
			/>
		</div>
	</WrapperStyled>
)

const WrapperStyled = styled.article`
	display: flex;

	> div {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: ${sizes.spacing.medium};

		> h3 svg {
			margin-left: ${sizes.spacing.small};
		}
	}
`

const ThumbnailStyled = styled.div`
	display: inline-block;
	position: relative;

	img {
		border-radius: ${borders.radius.tiny};
		max-height: 10rem;
	}

	svg {
		background-color: ${buttons.color.overlay.background};
		border: ${borders.width.medium} solid
			${buttons.color.overlay.content};
		border-radius: ${borders.radius.full};
		color: ${buttons.color.overlay.content};
		padding: 1rem 0.8rem 1rem 1.2rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 5rem;
		min-width: 5rem;

		&:hover {
			background-color: ${buttons.color.overlay.hover
				.background};
			border-color: ${buttons.color.overlay.hover.content};
			color: ${buttons.color.overlay.hover.content};
		}
	}
`

const Metadata = styled.div`
	display: flex;
	justify-content: space-between;

	svg {
		margin-right: ${sizes.spacing.small};
	}
`

import { env } from 'config'
import { objectToQueryParameters } from 'services/utils'

const authScopes = [
	'user-read-private',
	'user-read-email',
	'user-library-read',
]

const domains = {
	account: 'https://accounts.spotify.com',
	api: 'https://api.spotify.com/v1',
}

export const routes = {
	account: {
		code: callbackUrl =>
			`${domains.account}/authorize?` +
			objectToQueryParameters({
				response_type: 'code',
				client_id: env.spotify.clientId,
				scope: authScopes.join(' '),
				redirect_uri: callbackUrl,
			}),
		token: () => `${domains.account}/api/token`,
	},
	api: {
		favorites: params =>
			`${domains.api}/me/tracks?` +
			objectToQueryParameters(params || {}),
		profile: id =>
			!id
				? `${domains.api}/me`
				: `${domains.api}/users/${id}`,
		trackAudioFeaturesList: ({ idList }) =>
			`${domains.api}/audio-features?` +
			objectToQueryParameters({
				ids: idList.join(','),
			}),
	},
}

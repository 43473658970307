import React from 'react'
import styled from '@emotion/styled'
import { Heading4 } from 'ui/components/index'
import { borders, sizes } from 'ui/theme'

export const ParticipantsList = ({
	maxParticipants,
	participants,
	...rest
}) => (
	<ParticipantListStyled {...rest}>
		{participants
			.slice(0, maxParticipants)
			.map(({ image }) => (
				<img
					key={image}
					src={image}
					alt='Participant avatar'
				/>
			))}
		{participants.length > maxParticipants && (
			<Heading4 as='div'>
				+{participants.length - maxParticipants}
			</Heading4>
		)}
	</ParticipantListStyled>
)

const ParticipantListStyled = styled.div`
	display: flex;

	img:first-of-type {
		margin-left: 0;
	}

	img,
	div {
		border-radius: ${borders.radius.full};
		height: 3rem;
		margin-left: ${sizes.spacing.regular};
		width: 3rem;
	}

	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`

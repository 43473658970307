import React from 'react'
import styled from '@emotion/styled'
import { Header } from 'ui/components'
import { ContentArea } from './ContentArea'

export const ContentWithHeader = ({
	children,
	...rest
}) => (
	<WrapperStyled>
		<Header as='header' />
		<ContentArea as='main' {...rest}>
			{children}
		</ContentArea>
	</WrapperStyled>
)

const WrapperStyled = styled.div`
	height: 100vh;

	> header {
		position: fixed;
		top: 0;
	}

	> main {
		height: min-content;
		padding-top: 4rem;
	}
`

export const env = {
	hostname: process.env.REACT_APP_HOSTNAME,
	firebase: {
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	},
	spotify: {
		clientId: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
		clientSecret:
			process.env.REACT_APP_SPOTIFY_CLIENT_SECRET,
	},
}

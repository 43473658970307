// do NOT export this - don't use the palette directly
const colorPalette = {
	transparent: 'transparent',
	persianBlue: {
		// primary
		darker: '#111985',
		regular: '#3238ac',
		lighter: '#8287e1', // variant
	},
	tomato: {
		// secondary
		darker: '#fc3f31',
		regular: '#fc664d',
		lighter: '#fd8f7d', // variant
	},
	gray: {
		regular: '#484848', // On Background
	},
	white: {
		regular: '#ffffff', // On Primary
		opacityHigh: 'rgba(255,255,255,0.80)',
	},
	black: {
		regular: '#000000', // On Secondary
		opacityLow: 'rgba(0,0,0,0.125)',
		opacityLowest: 'rgba(0,0,0,0.05)',
	},
	snow: '#fff6f4',
	ghost: '#f4f4fb',
	carmine: '#b00020', // Error

	brand: {
		spotify: '#1DB954',
	},
}

export const font = {
	color: {
		primary: colorPalette.black.regular,
		secondary: colorPalette.gray.regular,
		inverted: colorPalette.white.regular,
	},
	lineHeight: {
		jumbo: '4.5rem',
		mega: '3.125rem',
		huge: '2.5rem',
		big: '2rem',
		large: '1.625rem',
		medium: '1.3rem',
		regular: '1.2rem',
		small: '1rem',
		tiny: '0.875rem',
	},
	size: {
		jumbo: '4rem', // 64
		mega: '2.625rem', // 42
		huge: '2.125rem', // 34
		big: '1.75rem', // 28
		large: '1.375rem', // 22
		medium: '1.125rem', // 18
		regular: '1rem', // 16
		small: '0.875rem', // 14
		tiny: '0.75rem', // 12
	},
	type: {
		regular:
			'"San Francisco", "Helvetica Neue", Arial, sans-serif',
		heading:
			'"San Francisco", "Helvetica Neue", Arial, sans-serif',
	},
	weight: {
		regular: {
			bold: 700,
			regular: 400,
		},
		heading: {
			bold: 700,
			semiBold: 600,
			medium: 500,
			regular: 400,
		},
	},
}

export const sizes = {
	size: {
		large: '60rem',
		medium: '48rem',
		small: '30rem',
		tiny: '20rem',
	},
	breakpoint: {
		large: 'min-width: 60rem', // 960
		medium: 'min-width: 48rem', // 768
		small: 'min-width: 30rem', // 480
		tiny: 'min-width: 20rem', // 320
	},
	spacing: {
		jumbo: '4rem',
		mega: '3rem',
		huge: '2rem',
		big: '1.75rem',
		large: '1.5rem',
		medium: '1.25rem',
		regular: '1rem',
		small: '0.5rem',
		tiny: '0.25rem',
	},
}

export const borders = {
	radius: {
		full: '100%',
		huge: '2rem',
		big: '1.75rem',
		large: '1.5rem',
		medium: '1.25rem',
		regular: '1rem',
		small: '0.5rem',
		tiny: '0.25rem',
	},
	shadow: {
		regular: `0 3px 4px ${colorPalette.black.opacityLowest}`,
	},
	type: {
		regular: `1px solid ${colorPalette.black.opacityLow}`,
	},
	width: {
		thin: '1px',
		medium: '0.185rem',
	},
}

export const buttons = {
	border: {
		radius: {
			full: borders.radius.full,
			medium: borders.radius.medium,
		},
		width: {
			thin: borders.width.thin,
		},
	},
	color: {
		primary: {
			background: colorPalette.persianBlue.regular,
			content: colorPalette.white.regular,
			hover: {
				background: colorPalette.persianBlue.darker,
				content: colorPalette.white.regular,
			},
		},
		inverted: {
			background: colorPalette.white.regular,
			content: colorPalette.persianBlue.regular,
			hover: {
				background: colorPalette.persianBlue.darker,
				content: colorPalette.white.regular,
			},
		},
		overlay: {
			background: colorPalette.white.opacityHigh,
			content: colorPalette.black.regular,
			hover: {
				background: colorPalette.white.regular,
				content: colorPalette.persianBlue.regular,
			},
		},
	},
	padding: {
		regular: `${sizes.spacing.small} ${sizes.spacing.medium}`,
	},
}

export const colors = {
	background: {
		primary: colorPalette.white.regular,
		secondary: colorPalette.snow,
		active: colorPalette.persianBlue.regular,
	},
	brand: {
		spotify: colorPalette.brand.spotify,
	},
}

import React from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch,
} from 'react-router-dom'
import { routes } from './routes'

export const App = () => (
	<Router>
		<Switch>
			{routes.map(({ component, ...props }) => (
				<Route key={props.path} {...props}>
					{component}
				</Route>
			))}

			<Route path={'*'}>
				{() => (window.location = '/404.html')}
			</Route>
		</Switch>
	</Router>
)

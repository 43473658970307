import { errorHandler } from 'services/errorHandler'
import {
	extractDataFromDocumentWithId,
	extractDocumentId,
	ensureDocumentExists,
} from './utils'

/*
{
	id: TrackId
	name: String
	url: Url

	durationInMs: Number
	isExplicit: Boolean
	discNumber: Number
	trackNumber: Number
	popularity: Number<0-100>

	artistList: Array<{
		id: ArtistId
		name: String
		url: Url
	}>

	album: {
		id: AlbumId
		name: String
		cover: {
			s: Url
			m: Url
			l: Url
		}
		url: Url
	}

	audioFeatures: {
		loudness: Number<-60.0, 0.0>

		key: Number<0-11>
			# C C#/Db D D#/Eb E F F#/Gb G G#/Ab A A#/Bb B
			# 0 1     2 3     4 5 6     7 8     9 10    11
		mode: 'MAJOR' | 'MINOR
		tempo: Number

		acousticness: Number<0.0-1.0>
		danceability: Number<0.0-1.0>
		energy: Number<0.0-1.0>
		instrumentalness: Number<0.0-1.0>
		liveness: Number<0.0-1.0>
		speechiness: Number<0.0-1.0>
		valence: Number<0.0-1.0>
	}
}
*/

const COLLECTION_TRACK = 'tracks'

export const getTrackById = firestore => id =>
	firestore()
		.collection(COLLECTION_TRACK)
		.doc(id)
		.get()
		.then(
			ensureDocumentExists(
				`Track with id "${id}" not wound`,
			),
		)
		.then(extractDataFromDocumentWithId)
		.catch(errorHandler)

export const createTrackList = firestore => list => {
	const store = firestore()
	const batch = store.batch()

	list.forEach(data =>
		batch.set(
			store.collection(COLLECTION_TRACK).doc(data.id),
			{
				...data,
				createdAt: Date.now(),
				updatedAt: null,
			},
		),
	)

	return batch.commit().catch(errorHandler)
}

import React from 'react'
import styled from '@emotion/styled'
import {
	Button,
	TextSmall,
	Heading1,
	Brand,
} from 'ui/components'
import { CenterContent } from 'ui/templates'
import { colors, sizes } from 'ui/theme'
import { init as client } from 'services/user'
import logo from './assets/logo.png'
import spotify from './assets/hiclipart.png'

export const LoginPage = () => (
	<WrapperStyled>
		<LogoStyled alt='Deepfuse logo' src={logo} />
		<Brand>DeepFuse</Brand>
		<Heading1 as='h2'>
			Create a collaborative playlist in a few seconds
		</Heading1>
		<SpotifyButton href={client().getAuthenticationUrl()}>
			Login with
			<SpotifyStyled alt='spotify brand' src={spotify} />
		</SpotifyButton>
		<TextSmall>
			By clicking log in, you agree to our Terms of Service
			and Privacy Policy
		</TextSmall>
	</WrapperStyled>
)

const WrapperStyled = styled(CenterContent)`
	h1 {
		margin-bottom: ${sizes.spacing.jumbo};
	}
`

const SpotifyButton = styled(Button)`
	align-items: center;
	background-color: ${colors.brand.spotify};
	border-color: ${colors.brand.spotify};
	display: flex;
	margin: 2rem 0;
`

const LogoStyled = styled.img`
	max-width: 100%;
	width: ${sizes.small};
`

const SpotifyStyled = styled.img`
	height: 1.65rem;
	margin-left: ${sizes.spacing.small};
`

export const extractDocumentId = document => {
	if (document.id) return document.id

	const { segments } = (document.im || document).path

	if (!segments || !Array.isArray(segments))
		throw new Error('Document malformed')

	return segments[segments.length - 1]
}

export const extractDataFromDocumentWithId = doc => ({
	...doc.data(),
	id: doc.id,
})

export const ensureDocumentExists = errorMessage => doc => {
	if (!doc.exists) throw new Error(errorMessage)

	return doc
}

export const firstFromQuerySnapshotOrFail = errorMessage => querySnapshot => {
	if (querySnapshot.empty) throw new Error(errorMessage)

	return querySnapshot.docs[0]
}

export const getQueryParameters = query =>
	query
		.replace(/^\?/, '')
		.replace(/#.*$/, '')
		.split('&')
		.map(query => query.split('='))
		.reduce(
			(acc, [name, value]) => ({ ...acc, [name]: value }),
			{},
		)

export const objectToQueryParameters = params =>
	Object.keys(params)
		.map(key => `${key}=${encodeURIComponent(params[key])}`)
		.join('&')

import firebase from 'firebase/app'
import 'firebase/firestore'
import { env } from 'config'
import { init as initClient } from './client'

const config = {
	appId: env.firebase.appId,
	apiKey: env.firebase.apiKey,
	projectId: env.firebase.projectId,
	databaseURL: `https://${env.firebase.projectId}.firebaseio.com`,
}

let client
const lazyLoadFirestore = () => {
	if (!client) client = firebase.initializeApp(config)

	return client.firestore()
}

export const init = () => initClient(lazyLoadFirestore)

const isDev = () => {
	try {
		return process.env.NODE_ENV === 'development'
	} catch {
		return false
	}
}

export const errorHandler = (error, context = null) => {
	if (!isDev()) return

	console.error('HANDLED ERROR', error, context)
}

import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { errorHandler } from 'services/errorHandler'
import { init as client } from 'services/user'
import { UserMenu } from 'ui/components/index'
import { borders, colors, sizes } from 'ui/theme'
import logo from './assets/logo.png'

export const Header = props => {
	const [user, setUser] = useState(null)

	useEffect(() => {
		client()
			.getUser()
			.then(user => {
				setUser({
					name: user.spotify.name,
					avatar: user.spotify.avatar,
				})
			})
			.catch(errorHandler)
	}, [])

	if (!user) return null

	return (
		<WrapperStyled {...props}>
			<header>
				<img src={logo} alt='deepfuse logo' />
				<UserMenu
					// @TODO use real data
					name={user.name}
					avatar={user.avatar}
					navigation={[
						{
							label: 'My Profile',
							path: '/my-profile',
						},
						{
							label: 'Logout',
							path: '/logout',
						},
					]}
				/>
			</header>
		</WrapperStyled>
	)
}

const WrapperStyled = styled.div`
	background-color: ${colors.background.primary};
	box-shadow: ${borders.shadow.regular};
	padding: 1rem;
	width: 100%;

	> header {
		display: flex;
		justify-content: space-between;
		margin: auto;
		max-height: 2rem;
		max-width: ${sizes.large};

		> img {
			max-height: 2rem;
		}
	}
`

export const getAuthenticationUrl = provider => () =>
	provider.getAuthenticationUrl()

export const authenticate = ({
	database,
	provider,
	storage,
}) => code =>
	provider.authenticate(code).then(token => {
		storage.setToken(token)

		return provider
			.fetchProfile()
			.then(data =>
				database.upsertUserByEmail({
					email: data.email,
					spotify: data,
				}),
			)
			.then(data => storage.setUserId(data.id))
			.then(() => token)
	})

export const clearAuthentication = ({ storage }) => () => {
	storage.removeUserId()
	storage.removeToken()
}

import styled from '@emotion/styled'
import { font } from 'ui/theme'

export const Poster = styled.h1`
	font-family: ${font.type.heading};
	font-size: ${font.size.jumbo};
	font-weight: ${font.weight.heading.medium};
	line-height: ${font.lineHeight.jumbo};
	margin: 0;
	padding: 0;
`

export const Brand = styled.h1`
	font-family: ${font.type.heading};
	font-size: ${font.size.mega};
	font-weight: ${font.weight.heading.semiBold};
	line-height: ${font.lineHeight.mega};
	margin: 0;
	padding: 0;
`

export const Heading1 = styled.h1`
	font-family: ${font.type.heading};
	font-size: ${font.size.huge};
	font-weight: ${font.weight.heading.medium};
	line-height: ${font.lineHeight.huge};
	margin: 0;
	padding: 0;
`

export const Heading2 = styled.h2`
	font-family: ${font.type.heading};
	font-size: ${font.size.big};
	font-weight: ${font.weight.heading.regular};
	line-height: ${font.lineHeight.big};
	margin: 0;
	padding: 0;
`

export const Heading3 = styled.h3`
	font-family: ${font.type.heading};
	font-size: ${font.size.large};
	font-weight: ${font.weight.heading.regular};
	line-height: ${font.lineHeight.large};
	margin: 0;
	padding: 0;
`

export const Heading4 = styled.h4`
	font-family: ${font.type.heading};
	font-size: ${font.size.medium};
	font-weight: ${font.weight.heading.medium};
	line-height: ${font.lineHeight.medium};
	margin: 0;
	padding: 0;
`

export const TextLarge = styled.span`
	font-family: ${font.type.regular};
	font-size: ${font.size.medium};
	font-weight: ${font.weight.regular.regular};
	line-height: ${font.lineHeight.medium};
	margin: 0;
	padding: 0;
`

export const Text = styled.span`
	font-family: ${font.type.regular};
	font-size: ${font.size.regular};
	font-weight: ${font.weight.regular.regular};
	line-height: ${font.lineHeight.regular};
	margin: 0;
	padding: 0;
`

export const TextSmall = styled.span`
	font-family: ${font.type.regular};
	font-size: ${font.size.small};
	font-weight: ${font.weight.regular.regular};
	line-height: ${font.lineHeight.small};
	margin: 0;
	padding: 0;
`

export const Link = styled.a`
	font-family: ${font.type.regular};
	font-size: ${font.size.tiny};
	font-weight: ${font.weight.regular.bold};
	line-height: ${font.lineHeight.tiny};
	margin: 0;
	padding: 0;
	text-decoration: underline;
`

import { generateRoute } from 'config'
import { init as initSpotify } from 'services/spotify'
import { init as initFirestore } from 'services/firestore'
import { init as initCookies } from 'services/cookieStorage'
import { init as initClient } from './client'

const keys = {
	TOKEN: 'token',
	USER_ID: 'userId',
}

export const init = () => {
	const cookies = initCookies()

	const getUserId = () => cookies.get(keys.USER_ID)
	const setUserId = id =>
		cookies.set(keys.USER_ID, id, { secure: true })
	const removeUserId = () => cookies.remove(keys.USER_ID)
	const setToken = token =>
		cookies.set(keys.TOKEN, token, { secure: true })
	const removeToken = () => cookies.remove(keys.TOKEN)

	const firestore = initFirestore()
	// @TODO hide provider name inside user namespace
	const spotify = initSpotify({
		token: cookies.find(keys.TOKEN),
		callbackUrl: generateRoute('oauthCallback', {
			full: true,
		}),
		onTokenChange: setToken,
	})

	return initClient({
		provider: spotify,
		database: firestore,
		storage: {
			getUserId,
			setUserId,
			removeUserId,
			setToken,
			removeToken,
		},
	})
}

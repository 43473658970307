import React, { useState } from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faChevronUp,
	faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import { Text, Link } from 'ui/components/index'
import { colors, borders, font, sizes } from 'ui/theme'

export const UserMenu = ({
	name,
	avatar,
	navigation,
	...rest
}) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<>
			<UserMenuStyled {...rest} isOpen={isOpen}>
				<button onClick={() => setIsOpen(!isOpen)}>
					<img src={avatar} alt='user-avatar' />
					<Text>{name}</Text>
					<FontAwesomeIcon
						icon={isOpen ? faChevronDown : faChevronUp}
					/>
				</button>
				<ul>
					{navigation.map(page => (
						<Link key={page.path} href={page.path}>
							<li>
								<Text>{page.label}</Text>
							</li>
						</Link>
					))}
				</ul>
			</UserMenuStyled>
			{isOpen && (
				<OverlayStyled onClick={() => setIsOpen(!isOpen)} />
			)}
		</>
	)
}

const OverlayStyled = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	background: rgba(0, 0, 0, 0.15);
	z-index: 100;
`

const UserMenuStyled = styled.nav`
	button {
		align-content: center;
		align-items: center;
		background: none;
		border: none;
		cursor: pointer;
		display: flex;
		flex-direction: row;

		img {
			border-radius: 100%;
			margin-right: ${sizes.spacing.small};
			max-height: 2rem;
		}

		svg {
			margin-left: ${sizes.spacing.small};
		}
	}

	ul {
		background-color: ${colors.background.secondary};
		border-radius: ${borders.radius.tiny};
		display: ${props => (props.isOpen ? 'block' : 'none')};
		list-style: none;
		margin-top: ${sizes.spacing.small};
		padding: ${sizes.spacing.tiny} 0;
		position: relative;
		z-index: 110;

		a {
			text-decoration: none;
		}

		li {
			color: ${font.color.secondary};
			cursor: pointer;
			padding: ${sizes.spacing.tiny} ${sizes.spacing.huge};
			text-decoration: none;

			&:hover {
				color: ${font.color.inverted};
				background-color: ${colors.background.active};
			}
		}
	}
`

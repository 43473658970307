import { routes } from './routes'
import {
	generateAuthorizationHeader,
	getResponseJsonOrFail,
} from './utils'

export const fetchProfile = token =>
	fetch(routes.api.profile(), {
		headers: generateAuthorizationHeader(token),
	})
		.then(getResponseJsonOrFail)
		.then(result => ({
			name: result.display_name,
			email: result.email,
			userId: result.id,
			avatar: (result.images[0] || {}).url,
			hasPaidAccount: result.product === 'premium',
		}))

import React, { useState } from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faPencilAlt,
	faCheck,
	faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { TextLarge } from 'ui/components/index'
import { font, sizes } from 'ui/theme'

export const EditableTitle = ({
	onChange,
	title,
	...rest
}) => {
	const [isEditEnable, setIsEditEnable] = useState(false)
	const toggleIsEditable = () =>
		setIsEditEnable(!isEditEnable)
	let input

	if (!isEditEnable)
		return (
			<TextLarge {...rest}>
				{title}
				<IconStyled
					icon={faPencilAlt}
					onClick={toggleIsEditable}
				/>
			</TextLarge>
		)

	return (
		<TextLarge as='div' {...rest}>
			<input
				ref={self => (input = self)}
				type='text'
				name='new-title'
				defaultValue={title}
			/>
			<IconStyled
				primary
				icon={faCheck}
				onClick={() => {
					if (input && input.value !== title)
						onChange(toggleIsEditable)

					toggleIsEditable()
				}}
			/>
			<IconStyled
				danger
				icon={faTimes}
				onClick={() => setIsEditEnable(!isEditEnable)}
			/>
		</TextLarge>
	)
}

const IconStyled = styled(FontAwesomeIcon)`
	color: ${font.color.regular};
	cursor: pointer;
	margin-left: ${sizes.spacing.regular};

	&:hover {
		color: ${({ primary, danger }) => {
			if (primary) return font.color.cta
			if (danger) return font.color.danger

			return font.color.regular
		}};
	}
`

import React from 'react'
import styled from '@emotion/styled'
import { Text } from './Typography'
import { buttons } from 'ui/theme'

const ButtonStyled = styled(Text)`
	background-color: ${buttons.color.primary.background};
	border: ${buttons.border.width.thin} solid
		${buttons.color.primary.background};
	border-radius: ${buttons.border.radius.medium};
	color: ${buttons.color.primary.content};
	padding: ${buttons.padding.regular};
	text-decoration: none;
`

export const Button = props => (
	<ButtonStyled
		as={props.href ? 'a' : 'button'}
		{...props}
	/>
)

import React from 'react'
import { getQueryParameters } from 'services/utils'
import { init as initClient } from 'services/user'
import { generateRoute } from 'config/routes'

export const OauthCallback = props => {
	const { code } = getQueryParameters(props.location.search)
	const client = initClient()
	client
		.authenticate(code)
		.then(() => client.refreshUserFavorites())
		.then(() => {
			props.history.push(generateRoute('home'))
		})

	if (!code) return <h1>missing code</h1>

	return <p>Loading...</p>
}

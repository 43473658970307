export const init = cookieManager =>
	Object.freeze({
		get: key => {
			const value = cookieManager.get(key)
			if (!value) throw new Error(`Key "${key}" not found`)

			return value
		},
		set: (key, value, options) => {
			cookieManager.set(key, value, {
				path: '/',
				...options,

				// @TODO find a proper fix for securing cookies
				// in a way JS can still handle them
				httpOnly: false,
				secure: false,
				sameSite: null,
			})
		},
		remove: (key, options = {}) =>
			cookieManager.remove(key, {
				path: '/',
				...options,
			}),
		find: key => {
			const value = cookieManager.get(key)
			if (!value) return null

			return value
		},
	})

import { errorHandler } from 'services/errorHandler'
import {
	extractDataFromDocumentWithId,
	extractDocumentId,
	ensureDocumentExists,
} from './utils'

/*
{
	id: PlaylistId
	name: String
	ownerId: UserId
	createdAt: DateTime
	updatedAt: DateTime | Null
	participants: Array<{ id: UserId }>
	configuration: {
		danceable: Number
		energy: Number
		speech: Number
		valence: Number
	}
	tracks: Array<{
		id: TrackId
		savedBy: Array<{ id: UserId }>
	}>
*/

const COLLECTION_PLAYLIST = 'playlists'

export const getPlaylistById = firestore => id =>
	firestore()
		.collection(COLLECTION_PLAYLIST)
		.doc(id)
		.get()
		.then(
			ensureDocumentExists(
				`Playlist with id "${id}" not found`,
			),
		)
		.then(extractDataFromDocumentWithId)
		.catch(errorHandler)

export const findPlaylistByOwnerId = firestore => id =>
	firestore()
		.collection(COLLECTION_PLAYLIST)
		.where('ownerId', '==', id)
		.get()
		.then(querySnapshot =>
			querySnapshot.docs.map(queryDocument => ({
				...queryDocument.data(),
				id: queryDocument.id,
			})),
		)
		.catch(errorHandler)

export const createPlaylist = firestore => data =>
	firestore()
		.collection(COLLECTION_PLAYLIST)
		.add({
			...data,
			createdAt: Date.now(),
			updatedAt: null,
		})
		.then(doc => ({
			...data,
			id: extractDocumentId(doc),
		}))
		.catch(errorHandler)

export const updatePlaylist = firestore => ({
	id,
	...data
}) =>
	getPlaylistById(id)
		.then(oldData => {
			const updatedAt = Date.now()

			firestore
				.collection(COLLECTION_PLAYLIST)
				.doc(id)
				.update({
					...oldData,
					...data,
					updatedAt,
				})
				.catch(errorHandler)

			return {
				...oldData,
				...data,
				updatedAt,
				id,
			}
		})
		.catch(errorHandler)

import {
	getPlaylistById,
	findPlaylistByOwnerId,
	createPlaylist,
	updatePlaylist,
} from './playlist'
import { getTrackById, createTrackList } from './track'
import {
	getUserById,
	getUserByEmail,
	createUser,
	updateUser,
	upsertUserByEmail,
} from './user'

export const init = firestore =>
	Object.freeze({
		// users
		getUserById: getUserById(firestore),
		getUserByEmail: getUserByEmail(firestore),
		createUser: createUser(firestore),
		updateUser: updateUser(firestore),
		upsertUserByEmail: upsertUserByEmail(firestore),

		// playlists
		getPlaylistById: getPlaylistById(firestore),
		findPlaylistByOwnerId: findPlaylistByOwnerId(firestore),
		createPlaylist: createPlaylist(firestore),
		updatePlaylist: updatePlaylist(firestore),

		// tracks
		getTrackById: getTrackById(firestore),
		createTrackList: createTrackList(firestore),
	})

import React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Heading3 } from 'ui/components/Typography'
import { buttons, sizes } from 'ui/theme'

export const CreatePlaylistCta = props => (
	<ButtonStyled as='button' {...props}>
		<FontAwesomeIcon icon={faPlus} />
		Create a new playlist
	</ButtonStyled>
)

const ButtonStyled = styled(Heading3)`
	align-items: center;
	border: none;
	background: none;
	color: ${buttons.color.inverted.content};
	cursor: pointer;
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;
	width: max-content;

	&:hover {
		color: ${buttons.color.inverted.hover.background};

		svg {
			color: ${buttons.color.inverted.hover.content};
			border-color: ${buttons.color.inverted.hover.content};
			background-color: ${buttons.color.inverted.hover
				.background};
		}
	}

	svg {
		border: ${buttons.border.width.medium} solid
			${buttons.color.inverted.content};
		border-radius: ${buttons.border.radius.full};
		padding: ${sizes.spacing.regular};
		min-height: 4rem;
		margin-right: ${sizes.spacing.small};
		min-width: 4rem;
	}
`

import { env } from './env'

const paths = {
	// public
	landing: '/',
	login: '/login',
	logout: '/logout',
	oauthCallback: '/oauth/callback',

	// participant
	playlist: '/playlist',

	// creator
	home: '/home',
}

export const generateRoute = (route, { full } = {}) => {
	const path = paths[route]

	if (!path) throw Error(`Route "${route}" is not defined`)

	return full
		? env.hostname.replace(/\/$/, '') +
				'/' +
				path.replace(/^\//, '')
		: path
}

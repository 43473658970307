export const mapRawAudioFeatures = audioFeatures => ({
	id: audioFeatures.id,
	audioFeatures: {
		loudness: audioFeatures.loudness,
		key: audioFeatures.key,
		mode: audioFeatures.mode === 1 ? 'MAJOR' : 'MINOR',
		tempo: audioFeatures.tempo,

		acousticness: audioFeatures.acousticness,
		danceability: audioFeatures.danceability,
		energy: audioFeatures.energy,
		instrumentalness: audioFeatures.instrumentalness,
		liveness: audioFeatures.liveness,
		speechiness: audioFeatures.speechiness,
		valence: audioFeatures.valence,
	},
})

export const mapRawArtist = artist => ({
	id: artist.id,
	name: artist.name,
	url: artist.external_urls.spotify,
})

export const mapRawTrack = track => ({
	id: track.id,
	name: track.name,
	url: track.external_urls.spotify,

	durationInMs: track.duration_ms,
	isExplicit: track.explicit,
	discNumber: track.disc_number,
	trackNumber: track.track_number,
	popularity: track.popularity,

	artistList: track.artists.map(mapRawArtist),

	album: {
		id: track.album.id,
		name: track.album.name,
		// @TODO improve image mapper to a more stable way
		cover: {
			s: track.album.images[2].url,
			m: track.album.images[1].url,
			l: track.album.images[0].url,
		},
		url: track.album.external_urls.spotify,
	},
})

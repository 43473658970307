import { mapRawAudioFeatures, mapRawTrack } from './mappers'
import { routes } from './routes'
import {
	generateAuthorizationHeader,
	getResponseJsonOrFail,
} from './utils'
import { errorHandler } from 'services/errorHandler'

const fetchTrackAudioFeaturesList = ({ token, idList }) =>
	fetch(routes.api.trackAudioFeaturesList({ idList }), {
		headers: generateAuthorizationHeader(token),
	})
		.then(getResponseJsonOrFail)
		.then(({ audio_features: audioFeatures }) =>
			audioFeatures.map(mapRawAudioFeatures),
		)

export const findFavorites = token =>
	fetch(
		routes.api.favorites({
			limit: 50, // @TODO remove max 50 (handle pagination)
		}),
		{
			headers: generateAuthorizationHeader(token),
		},
	)
		.then(getResponseJsonOrFail)
		.then(({ items }) => {
			const trackListByKey = {}
			items.forEach(({ track: rawTrack }) => {
				const track = mapRawTrack(rawTrack)
				trackListByKey[track.id] = track
			})

			return fetchTrackAudioFeaturesList({
				token,
				idList: Object.keys(trackListByKey),
			}).then(audioFeaturesList =>
				audioFeaturesList.map(audioFeature => ({
					...audioFeature,
					...trackListByKey[audioFeature.id],
				})),
			)
		})
		.catch(errorHandler)

import {
	authenticate,
	clearAuthentication,
	getAuthenticationUrl,
} from './auth'
import { getUser, refreshUserFavorites } from './user'

export const init = ({ provider, database, storage }) =>
	Object.freeze({
		authenticate: authenticate({
			provider,
			database,
			storage,
		}),
		clearAuthentication: clearAuthentication({ storage }),
		getAuthenticationUrl: getAuthenticationUrl(provider),
		getUser: getUser({ database, storage }),
		refreshUserFavorites: refreshUserFavorites({
			database,
			provider,
			storage,
		}),
	})

import { findFavorites } from './favorites'
import { routes } from './routes'
import {
	fetchAuthTokens,
	fetchRefreshedAuthTokens,
	isTokenValid,
	isTokenExpired,
} from './auth'
import { fetchProfile } from './user'

export const init = ({
	token,
	callbackUrl,
	onTokenChange,
}) => {
	let currentToken = token

	const updateToken = token => {
		currentToken = token
		onTokenChange(token)

		return token
	}

	const refreshToken = token =>
		fetchRefreshedAuthTokens(token).then(updateToken)

	const authenticate = code =>
		fetchAuthTokens({ code, callbackUrl }).then(updateToken)

	if (currentToken && !isTokenValid(currentToken))
		throw Error('Provided token is invalid')

	// @TODO wrap non authentication methods with
	// refreshToken() on 401 failure
	return Object.freeze({
		getAuthenticationUrl: () =>
			routes.account.code(callbackUrl),
		authenticate,
		fetchProfile: () => {
			if (!isTokenExpired(currentToken))
				return fetchProfile(currentToken)

			return refreshToken(currentToken).then(fetchProfile)
		},
		findFavorites: () => findFavorites(currentToken),
	})
}
